import React, { ReactNode } from 'react'
import Link from 'next/link'
import cs from 'classnames'
import styles from './UiLink.module.scss'

interface Props {
  href: string
  children?: ReactNode
  className?: string
  download?: boolean
  blank?: boolean
  ariaLabel?: string
  onClick?: () => void
}

const UiLink: React.FC<Props> = ({
  href,
  children,
  className,
  download,
  blank,
  onClick,
  ariaLabel,
}) => (
  <Link href={href} legacyBehavior>
    {onClick ? (
      <a
        aria-label={ariaLabel}
        className={cs(className, styles.link)}
        onClick={onClick}
        onKeyDown={onClick}
        role='button'
        tabIndex={0}
      >
        {children}
      </a>
    ) : (
      <a
        aria-label={ariaLabel}
        className={cs(className, styles.link)}
        download={download}
        target={blank ? '_blank' : '_self'}
      >
        {children}
      </a>
    )}
  </Link>
)

export default UiLink
